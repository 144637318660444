import React from "react"

import "../../styles/index.scss"
import "../../styles/secondary_pages.scss"

import Layout from "../../components/layout"
import SEO from "../../components/seo"


import { Link, graphql } from "gatsby"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'


import BannerMobile from "../../images/editorial/rev-cycle-banner-mobile.jpg"
import Banner from "../../images/editorial/rev-cycle-banner.jpg"

import IconRevCycle from "../../images/svg/icon-rev-cycle.svg"
import IconContracting from "../../images/svg/icon-contracting-credentialing.svg"
import IconConsulting from "../../images/svg/icon-mgmt-services-consulting.svg"
import IconPracticeMgmt from "../../images/svg/icon-practice-mgmt.svg"

import ImgGreatestPotential from "../../images/svg/isometric-greatest-potential.svg"
import ImgRevCycle from "../../images/svg/icon-rev-cycle.svg"
import ImgNewFramework from "../../images/svg/new-framework.svg"
import ImgDashboard from "../../images/dashboard.jpg"
import ImgTrueAlly from "../../images/svg/true-ally.svg"
import ImgTurnKeyOneSolution from "../../images/svg/turn-key-one-solution.svg"
import ImgFirstClass from "../../images/svg/first-class-experience.svg"
import ImgTarget from "../../images/svg/target.svg"
const RevCycle = () => (

  <React.Fragment>
    <SEO title="Revenue Cycle Management" description="Invigorate your bottom line. For more than 35 years, MSM has been partnering with practices to maximize profits and drive down costs." />

    <Layout>

      <section id="sct-uvp" className="container ctr-headline secondary-headline">
        <div className="frame-image">
          <article>

            <h2>Invigorate your
            <br />
              <span>bottom line. </span>
            </h2>
            <p>As healthcare gets more complex, so does your revenue cycle. How can you maximize profits and drive down costs
              in an industry that’s perpetually shifting under your feet? And are you getting paid what you’re due, or leaving
            revenue on the table?</p>
            <button className="btn-primary">
              <a href="#learnmore"> Learn More</a>
            </button>
          </article>

          <picture>
            <source srcSet={BannerMobile} media="(max-width:600px)" />
            <source srcSet={Banner} />
            <img src={Banner} alt="" className="lazyload" />
          </picture>


        </div>
      </section>

      <section className="container ctr-potential ctr-newframework" id="sct-newframework">
        <div className="animated ">
          <h2>You need a new framework, because the status quo is no longer effective.</h2>
          <p>MSM offers advanced, end-to-end revenue cycle management for the modern medical operation. We combine knowledgeable experts with superior technology to help physician groups and independent practices increase their bottom line.</p>

          <p>MSM enables practices to focus on patients—and gives you the confidence that your revenue cycle is performing at its best.
</p>


        </div>


        <img src={ImgNewFramework} />

      </section>
      <a name="learnmore" />
      <section className="container ctrinfoGraphicContainer" >
        <h3>Here are some ways MSM can help your practice grow: </h3>
        <div className="content">
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Watch your revenue multiply
      </h4>
              <p>You want to see financial gain, so we set ambitious, data-driven goals and generate unparalleled results. You can visualize profits as they—and your practice—grow.</p>
            </div>
            <div className="svg">
              <img src={ImgDashboard} style={{ boxShadow: "0 0 43px 0 rgba(0, 0, 0, 0.1)" }} alt="" />
            </div>
          </article>

          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Partner with a true ally
      </h4>
              <p>We are more than just a vendor; at MSM, we have your practice’s best interests in mind. Our client approach is high-touch and personalized, because your success is
our success. </p>
            </div>
            <div className="svg">
              <img src={ImgTrueAlly} />
            </div>

          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Turnkey convenience from day one
      </h4>
              <p>We eliminate your admin burdens by handling everything you need to run efficiently—from contracting to credentialing to reporting and data analytics. Ours is a ready-made solution, starting the moment we begin
our relationship.</p>
            </div>
            <div className="svg svg-vert">
              <img src={ImgTurnKeyOneSolution} />
            </div>

          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Treat patients to a first-class
experience
      </h4>
              <p>Patients will always have billing questions. When they do, MSM can assist with informed, compassionate answers and guidance. We take pride in caring for patients like you do. </p>
            </div>
            <div className="svg svg-vert">

              <img src={ImgFirstClass} />
            </div>
          </article>
        </div>

      </section>

      <section className="container list-container" style={{ backgroundColor: "#f6f7fa" }}>
        <h3>Team up with specialists in your specialty </h3>
        <p>Whether it’s primary care or cardiology, you need someone who understands your field. At MSM, we have ingrained experience in your specialty. Our mastery includes:</p>

        <ul className="ctr-list-component">
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Autism / ABA / Therapy</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Cardiology / Interventional</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Dermatology</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />ENT</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Hospital Medicine / Critical Care</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Primary Care / Internal Medicine</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Ophthalmology</li>

          <li>
            <FontAwesomeIcon icon={faCaretRight} />Orthopedics</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />OBGYN </li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Nephrology</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Radiology / Interventional</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Surgery / Surgery Centers (ASC)</li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Urgent Care </li>
          <li>
            <FontAwesomeIcon icon={faCaretRight} />Urology</li>
        </ul>

        <div className="svg">
          <img src={ImgTarget} />
        </div>
      </section>

      <section className="container ctr-cta" style={{ backgroundColor: "#16cac3", color: "#fff" }}>
        <h3>Are you ready to start maximizing your practice’s revenue?</h3>
        <p> Take the first step, and learn more about MSM’s services today.</p>
        <button className="btn-primary btn-inverse" style={{ backgroundColor: "#fff", color: "#16cac3" }}>
          <Link to="/contact">Get Started</Link>
        </button>
      </section>

    </Layout>




  </React.Fragment >

)

export default RevCycle;